import React, { Component } from 'react'

type Props = {}

type State = {
  isMounted: boolean;
}

export default class ClientOnlyWrapper extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isMounted: false,
    }
  }

  componentDidMount() {
    // this will method only gets used by the client/browser
    this.setState({ isMounted: true });
  }
  render() {
    if (!this.state.isMounted) {
      return null
    }
    return (
      <React.Fragment>{this.props.children}</React.Fragment>
    )
  }

}